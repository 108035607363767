<template>
  <div>
    <v-layout wrap justify-center py-16>
      <v-flex xs12 py-16>
        <v-layout wrap justify-center>
          <v-flex xs12 sm8>
            <span
              style="color: #000000; font-family: poppinsbold; font-size: 25px"
            >
              <span
                style="
                  color: #3cb043;
                  font-family: poppinsbold;
                  font-size: 25px;
                "
                >The examination has been completed successfully.</span
              >
            </span>
            <br />
            <br />
            <span
              style="
                color: #000000;
                font-family: poppinsmedium;
                font-size: 20px;
              "
              >Upon successful submission of your answers, the certificate will
              be issued following a comprehensive assessment of the essay
              questions.</span
            >
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12>
            <span
              style="
                color: #3cb043;
                font-family: opensanssemibold;
                font-size: 25px;
              "
            >
              {{ formattedTime }}</span
            >
            <br />
            <span
              style="
                color: #000000;
                font-family: poppinsregular;
                font-size: 13px;
              "
              >Second</span
            >
            <br />
            <span
              style="color: black; font-family: poppinsbold; font-size: 20px"
              >You will be automatically redirected to the home</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      exam: [],
      dirty_form: true,
      showDialog: false,
      currentTime: 20000,
      course: "",
    };
  },
  computed: {
    formattedTime() {
      const remaining = this.currentTime;
      // const hours = Math.floor(remaining / 3600000)
      //   .toString()
      //   .padStart(2, "0");
      // const minutes = Math.floor((remaining % 3600000) / 60000)
      //   .toString()
      //   .padStart(2, "0");
      const seconds = Math.floor((remaining % 60000) / 1000)
        .toString()
        .padStart(2, "0");
      return `${seconds}`;
    },
  },
  created() {
    this.updateTimer();
  },
  beforeMount() {
    setTimeout(
      function () {
        this.$router.push({ path: "/" });
      }.bind(this),
      20000
    );
  },
  //   beforeRouteLeave(to, from, next) {
  //   // Check if the user is trying to navigate back to the exam page
  //   if (from.name === "examComplete") {
  //     // Check if the exam has been completed (you may need a variable to track this)
  //     // const examCompleted = /* Check whether the exam has been completed, e.g., using a variable or state */;

  //     if(from.name === "Exam Section") {
  //       // If the exam is completed, prevent navigation back to the exam page
  //       next(false);
  //     }
  //     else
  //     next();

  // }
  // else
  //     next();
  //   },
  methods: {
    updateTimer() {
      setInterval(() => {
        if (this.currentTime > 0) {
          this.currentTime -= 1000; // Subtract 1 second
        }
      }, 1000);
    },
  },
};
</script>